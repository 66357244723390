import React, { useEffect } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

// Hooks
import { useAppContext } from '../state';

// Components
import { PageMeta } from '../components/pageMeta';
import { PortableTextBlock } from '../components/sanity/portableTextBlock';
import { PostCard } from '../components/blog/postCard';
import { VideoModule } from '../components/modules/videoModule';

const BlogPost = ({ data: { page, relatedPosts } }) => {
  const { meta, title, postDate, author, postImage, postBody, date } = page || {};
  const { setReverseNav, setHideFooter } = useAppContext();

  const { href } = useLocation();

  useEffect(() => {
    document.documentElement.classList.add('post');
    document.documentElement.classList.remove('index');
    document.documentElement.classList.remove('themeDark');
    setReverseNav(false);
    setHideFooter(false);
  }, [setReverseNav, setHideFooter]);

  return (
    <>
      <PageMeta {...meta} />
      {/* Post Body */}
      <div className="grid grid-cols-14">
        {/* Intro */}
        <div className="col-start-2 col-end-14 pt-32 pb-10 lg:col-start-1 lg:pl-24 lg:col-end-6">
          <div className="lg:sticky top-blogOffset pt-10 grid lg:min-h-blogSideBar lg:pb-20">
            <div className="prose lg:prose-lg 2xl:prose-xl">
              <h1 className="font-sans">{title}</h1>
            </div>
            <div className="grid mt-10 self-end prose prose-md">
              <div>
                <h4 style={{ fontSize: '11px' }} className="font-bold">Date</h4>
                <h4 className="text-lg">{date ? date : postDate}</h4>
              </div>
              <div>
                <h4 style={{ fontSize: '11px' }} className="font-bold">Author</h4>
                <h4 className="text-lg">{author}</h4>
              </div>
              {/* Social */}
              <div>
                <h4 style={{ fontSize: '11px' }} className="font-bold">Share</h4>
                <div className="flex">
                  <div className="mr-4">
                    <FacebookShareButton url={href}>Facebook</FacebookShareButton>
                  </div>
                  <div className="mr-4">
                    <TwitterShareButton url={href}>Twitter</TwitterShareButton>
                  </div>
                  <div className="mr-4">
                    <EmailShareButton url={href}>Email</EmailShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="col-start-1 col-end-15 pb-32 lg:border-l lg:border-current lg:col-start-7 lg:pt-blogOffset">
          {/* Post Image */}
          {postImage && postImage.asset && (
            <div className="aspect-w-1 aspect-h-1 relative lg:aspect-w-5 lg:aspect-h-4">
              <Image {...postImage} className="absolute w-full h-full top-0 left-0 object-cover" />
            </div>
          )}
          {/* Post Content */}
          <div className="px-gutter mt-10 xl:px-32 max-w-7xl mx-auto">
            <PortableTextBlock text={postBody} className="prose prose-lg md:prose-xl xl:mt-24" />
            {/* Realated Posts */}
            <div className="mt-24">
              <h2 className="text-3xl font-sans pb-16">More insights</h2>
              <div className="grid divide-y divide-logic-navy">
                {relatedPosts.edges.map(({ node }) => (
                  <PostCard {...node} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      author
      date(locale: "NZ", formatString: "DD MMMM YYYY")
      postDate: _createdAt(locale: "NZ", formatString: "DD MMMM YYYY")
      postBody: _rawPostBody(resolveReferences: { maxDepth: 10 })
      postImage {
        ...ImageWithPreview
      }
      meta {
        ...MetaCardFields
      }
    }
    relatedPosts: allSanityBlogPost(
      filter: { slug: { current: { ne: $slug } } }
      sort: { order: DESC, fields: _createdAt }
      limit: 3
    ) {
      edges {
        node {
          title
          postExcerpt
          postImage {
            ...ImageWithPreview
          }
          postDate: _createdAt(locale: "NZ", formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`;
